import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faBars,
  faBell,
  faCheckDouble,
  faSearch,
  faUserCircle,
  faTimes,
  faExclamationCircle,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

const icons = [
  faArrowLeft,
  faBars,
  faBell,
  faCheckDouble,
  faSearch,
  faUserCircle,
  faTimes,
  faExclamationCircle,
  faSignInAlt,
];

library.add(...icons);
