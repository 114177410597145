export const SET_CONTENT_LIST = 'content/setList';
export const PUSH_CONTENT_LIST = 'content/pushList';
export const SET_CONTENT_LIST_META = 'content/setListMeta';
export const SET_SELECTED_CONTENT = 'content/setSelected';
export const UNSET_SELECTED_CONTENT = 'content/unsetSelected';
export const SET_CONTENT_FILTER = 'content/setFilter';
export const SET_SORTING_FILTER = 'content/setSortingFilter';
export const SET_CATEGORY_FILTER = 'content/setCategoryFilter';
export const SET_REQUIRES_AUTH = 'content/setRequiresAuth';
export const SET_REQUIRES_AUTH_MAP = 'content/setRequiresAuthMap';
export const SET_IS_SEARCH_RESULT = 'content/set-is-search-result';
export const UNSET_IS_SEARCH_RESULT = 'content/unset-is-search-result';
export const SET_IS_LOADING = 'content/set-is-loading';
export const UNSET_IS_LOADING = 'content/unset-is-loading';
